/* You can add global styles to this file, and also import other style files */
$dark: #024565;
$border-color: desaturate(lighten($dark, 50%), 70%);
$font-family-base: "Noto Sans JP", sans-serif;
$font-weight-base: 300;
$font-size-base: 0.9rem;
$theme-colors: (
  "primary": #0d6efd,
  "secondary": #6c757d,
  "success": #198754,
  "info": #0dcaf0,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #f8f9fa,
  "dark": $dark,
);

@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;600&display=swap");

// $fa-font-path: "node_modules/@fortawesome/fontawesome-free/webfonts";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "~highlight.js/styles/vs.css";

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
   * Sidebar
   */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);

  .nav-link {
    font-weight: 500;
    color: #333;
    .feather {
      margin-right: 4px;
      color: #999;
    }
    &.active {
      color: #007bff;
    }
    &:hover .feather,
    &.active .feather {
      color: inherit;
    }
  }

  .sidebar-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

/*
   * Content
   */

[role="main"] {
  padding-top: 64px; /* Space for fixed navbar */
}

/*
   * Navbar
   */

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
  }
}

/* FORMS */
input.ng-valid,
select.ng-valid,
textarea.ng-valid {
  border-left: 6px solid $success;
}

input.ng-invalid,
select.ng-invalid,
textarea.ng-invalid {
  border-left: 6px solid $danger;
}

ckeditor.ng-valid div.ck-editor__editable {
  border-left: 6px solid $success !important;
}
ckeditor.ng-invalid div.ck-editor__editable {
  border-left: 6px solid $danger !important;
}
.ck.ck-balloon-panel {
  z-index: 2000 !important;
}

ngb-timepicker.ng-valid input {
  border-left: 6px solid $success;
}
ngb-timepicker.ng-invalid input {
  border-left: 6px solid $danger;
}
button.calendar,
button.calendar:active {
  width: 2.75rem;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=") !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
}
.ngb-toasts {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0.5em;
  z-index: 1200;
}
